import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google'

import '@particle-network/connect-react-ui/dist/index.css';



const GOOGLE_CLIENT_ID = "722303010951-afqudckov95dlsfgvcgbavlie6o4mbcj.apps.googleusercontent.com"



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
  <App />
  </GoogleOAuthProvider>
 
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals    >
         
  //  </GoogleOAuthProvider>  
reportWebVitals();




// <ModalProvider
//       options={{
//           projectId: '728abe3c-03d6-48e8-a322-c58e0f57dfd7',
//           clientKey: 'cFw2Gvfv24oXH6ka5Y4JU7crp9Fmuv46fcqvHd9k',
//           appId: 'bcf1f843-891b-4e66-a1b0-3c367f6314a3',
//           chains: [
//               Ethereum,
//               EthereumGoerli,
//               Solana, SolanaDevnet
//           ],
//           particleWalletEntry: {    //optional: particle wallet config
//               displayWalletEntry: true, //display wallet button when connect particle success.
//               defaultWalletEntryPosition: WalletEntryPosition.TR,
//               supportChains:[
//                   Ethereum,
//                   EthereumGoerli,
//                   Solana
//               ],
//               customStyle: {}, //optional: custom wallet style
//           },
//           securityAccount: { //optional: particle security account config
//               //prompt set payment password. 0: None, 1: Once(default), 2: Always
//               promptSettingWhenSign: 1,
//               //prompt set master password. 0: None(default), 1: Once, 2: Always
//               promptMasterPasswordSettingWhenLogin: 1
//           },
//           wallets: [
//             ...evmWallets({ projectId: '120426b8ecb186feca9867d93a017036', showQrModal: false }),
//             ...solanaWallets(),
//           ],
//           /*wallets: evmWallets({ 
//               projectId: '120426b8ecb186feca9867d93a017036', //replace with walletconnect projectId
//               showQrModal: false
//            }),*/
//           }}
//           theme={'auto'}
//           language={'en'}   //optional：localize, default en
//           
//           particleAuthSort={[    //optional：display particle auth items and order
//               'email',
//               'google',
//               'discord',
//               'phone',
//               'apple',
//               'facebook'
//           ]}
//       >
          
//       </ModalProvider>
    
      