import logo from './logo.svg';
import './App.css';
import Play from './Play';




function App({root}) {
  return (
    <div className="App" style={{backgroundColor:'#000'}}>
        <Play />
    </div>
  );
}

export default App;
